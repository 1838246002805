import { Row, Col } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";

import i18n from "i18next";
import {
  FooterWrapper,
  FooterSection,
  Title,
  Large,
  Empty,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: { t: TFunction }) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <FooterWrapper>
      <FooterSection>
        {/* <Row justify="space-between">
          <Col lg={4} md={4} sm={12} xs={12}>
            <Large to="/">Privacy Policy</Large>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12}>
            <Large to="/">Terms & Conditions</Large>
          </Col>
          <Col lg={16} md={16} sm={12} xs={12}>
            <Empty />
          </Col>
        </Row> */}
        <Row justify="space-between">
          <Col lg={10} md={10} sm={12} xs={12}>
            <Title>© 2024 Crypto Guardian Corp.</Title>
          </Col>
          <Col lg={8} md={8} sm={12} xs={12}>
            <Empty />
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}>
            <Empty />
          </Col>
        </Row>
      </FooterSection>
    </FooterWrapper>
  );
};

export default withTranslation()(Footer);
