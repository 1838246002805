import { useState } from "react";
import { Row  } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import { useHistory } from 'react-router-dom';
import { SvgIcon } from "../../common/SvgIcon";
import {
  HeaderSection,
  HeaderContainer,
  LogoContainer,
  NotHidden,
  CustomNavLinkSmall,
  Span,
} from "./styles";

const Header = ({ t }: { t: TFunction }) => {
  const [visible, setVisibility] = useState(false);
  const history = useHistory();

  const toggleButton = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
    };
    return (
      <>
        <CustomNavLinkSmall onClick={() => history.push('/') }>
          <Span>{t("Menu_Home")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("Services") }>
          <Span>{t("Menu_Services")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("Why") }>
          <Span>{t("Menu_Why")}</Span>
        </CustomNavLinkSmall>
        {/* <CustomNavLinkSmall onClick={() => scrollTo("Market") }>
          <Span>{t("Menu_Market")}</Span>
        </CustomNavLinkSmall> */}
        <CustomNavLinkSmall onClick={() => scrollTo("How") }>
          <Span>{t("Menu_How")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall
          // style={{ width: "180px" }}
          onClick={() => scrollTo("About")}
        >
          <Span>{t("Menu_About")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall
          // style={{ width: "180px" }}
          onClick={() => scrollTo("Faq")}
        >
          <Span>{t("Menu_Faq")}</Span>
        </CustomNavLinkSmall>
      </>
    );
  };

  return (
    <HeaderSection>
      <HeaderContainer>
        <Row justify="space-between" align="middle">
          <LogoContainer to="/" aria-label="homepage">
            {/* <SvgIcon src="logo.png" width="90px" height="76px" /> */}
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          {/* <Burger onClick={toggleButton}>
            <Outline />
          </Burger> */}
        </Row>
        {/* <Drawer closable={false} open={visible} onClose={toggleButton}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={toggleButton}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer> */}
      </HeaderContainer>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
