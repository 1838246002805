import styled from "styled-components";
import { Link } from "react-router-dom";

export const FooterWrapper = styled("footer")`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(0, 0, 0);
  padding: 30px 0 30px 0;
`;

export const FooterSection = styled("div")`
  width: 1080px;
`;

export const Title = styled("h4")`
  font-weight: normal;
  font-size: 16px;
  text-transform: capitalize;
  color: #6C6C6C;
  // margin-top: 40px;
`;

export const Large = styled(Link)`
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  cursor: pointer;
  line-height: 20px;
  display: block;

  :hover {
      color: #FFFFFF;
  }
`;

export const Empty = styled("div")`
  position: relative;
  height: 22px;
`;
